<div class="main-content">
    <div class="container-fluid">
        <div class="row">
            <div class="col-sm-12 col-md-6">
                <mat-accordion [multi]="true">
                    <mat-expansion-panel [expanded]="true">
                        <mat-expansion-panel-header>
                            <mat-panel-title style="font-weight: bold">
                                Customer Details
                            </mat-panel-title>
                        </mat-expansion-panel-header>

                        <div *ngFor="let user of users">
                            <div class="mb-1 icon-text-pair">
                                <i
                                    class="material-icons md-dark mr-2"
                                    style="font-size: 25px"
                                    >person</i
                                >
                                <label
                                    class="mb-0 card-title"
                                    style="font-size: 20px"
                                    >{{ user.name }}</label
                                >
                            </div>

                            <div class="mb-1 icon-text-pair">
                                <i
                                    class="material-icons md-dark mr-2"
                                    style="font-size: 25px"
                                    >phone</i
                                >
                                <label
                                    class="mb-0 card-title"
                                    style="font-size: 20px"
                                    >+{{ user.countryCode }}-
                                    {{ user.phone }}</label
                                >
                            </div>
                            <div class="mb-2 icon-text-pair">
                                <i
                                    class="material-icons md-dark mr-2"
                                    style="font-size: 25px"
                                    >mail</i
                                >
                                <label
                                    class="mb-0 card-title"
                                    style="font-size: 20px"
                                    >{{ user.email }}</label
                                >
                            </div>
                            <div class="mb-1 icon-text-pair">
                                <i
                                    class="material-icons md-dark mr-2"
                                    style="font-size: 25px; color: green"
                                    >location_on</i
                                >

                                <label
                                    class="mb-0 card-title"
                                    style="font-size: 15px"
                                    >{{ user.pickupAddress }}</label
                                >
                            </div>
                            <div class="mt-2 mb-2 icon-text-pair">
                                <i
                                    class="material-icons md-dark mr-2"
                                    style="font-size: 25px; color: red"
                                    >location_on</i
                                >

                                <label
                                    class="mb-0 card-title"
                                    style="font-size: 15px"
                                    >{{ user.dropAddress }}</label
                                >
                            </div>
                        </div>
                    </mat-expansion-panel>
                </mat-accordion>
            </div>
            <div class="col-sm-12 col-md-6">
                <mat-accordion [multi]="true">
                    <mat-expansion-panel [expanded]="true">
                        <mat-expansion-panel-header>
                            <mat-panel-title style="font-weight: bold">
                                weekly Commute Details
                            </mat-panel-title>
                        </mat-expansion-panel-header>
                        <div>
                            <table
                                style="
                                    width: 100%;
                                    border-collapse: collapse;
                                    text-align: left;
                                    margin-bottom: 10px;
                                "
                            >
                                <thead>
                                    <tr>
                                        <th
                                            style="
                                                border: 1px solid #ccc;
                                                padding: 8px;
                                            "
                                        >
                                            Week
                                        </th>
                                        <th
                                            style="
                                                border: 1px solid #ccc;
                                                padding: 8px;
                                            "
                                        >
                                            Pickup Time
                                        </th>
                                        <th
                                            style="
                                                border: 1px solid #ccc;
                                                padding: 8px;
                                            "
                                        >
                                            Drop Time
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <ng-container *ngFor="let user of users">
                                        <tr
                                            *ngFor="
                                                let commute of user.weeklyCommute
                                            "
                                        >
                                            <td
                                                style="
                                                    border: 1px solid #ccc;
                                                    padding: 8px;
                                                "
                                            >
                                                {{ commute.week }}
                                            </td>
                                            <td
                                                style="
                                                    border: 1px solid #ccc;
                                                    padding: 8px;
                                                "
                                            >
                                                {{ commute.pickupTime }}
                                            </td>
                                            <td
                                                style="
                                                    border: 1px solid #ccc;
                                                    padding: 8px;
                                                "
                                            >
                                                {{ commute.dropTime }}
                                            </td>
                                        </tr>
                                    </ng-container>
                                </tbody>
                            </table>
                        </div>
                    </mat-expansion-panel>
                </mat-accordion>
            </div>
        </div>
    </div>
</div>
