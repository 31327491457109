import { Injectable } from "@angular/core";
import {
    HttpClient,
    HttpParams,
    HttpHeaders,
    HttpErrorResponse,
    HttpRequest,
} from "@angular/common/http";
import { environment } from "../../environments/environment";
import { ListingResponse } from "../models/listing-response";
import { Category } from "../models/category";
import { TripSheet } from "../models/trip-sheet";
import { throwError, Observable, of, concat } from "rxjs";
import { map, retryWhen, flatMap, delay, take } from "rxjs/operators";
import { AuthResponse } from "../models/auth-response";
import { Organization } from "../models/organization";
import { Hub } from "../models/hub";
import {
    VehicleSummary,
    ProductImage,
    BrandDescriptor,
    Vehicle,
} from "../models/vehicle";
import { Brand } from "../models/brand";
import { Plate } from "../models/plate";
import { User, TrackingInfo } from "../models/user";
import { RideRequest, RideEvent } from "../models/ride-request";
import { RateCard } from "../models/rate-card";
import { FareEstimate } from "../models/fare-estimate";
import { City } from "../models/city";
import { Package } from "../models/package";
import { SourceNotification } from "../models/notification";
import { PaymentDetails } from "../models/paymentDetails";
import { RideEnquiry } from "../models/rideEnquiry";
import { EmailKind } from "../models/email";
import { PhoneKind } from "../models/phone";
import { Business } from "../models/business";
import { DailyCommuteResponse, DailyCommute } from "../models/daily-commute";
import { ImageDescriptor, ImageResponse } from "../models/Image-Descriptor";

const RETRY_COUNT = 3;

@Injectable({
    providedIn: "root",
})
export class VehoWebService {
    constructor(private http: HttpClient) {}

    private onError(errors: Observable<any>) {
        return errors
            .pipe(
                flatMap((e: any) => {
                    if (-1 < [500, 502, 503].indexOf(e.status)) {
                        return of(e.status).pipe(delay(1000));
                    } else {
                        return throwError(e);
                    }
                })
            )
            .pipe(take(RETRY_COUNT))
            .pipe((o) =>
                concat(
                    o,
                    throwError({
                        errorCode: "NETWORK_ERROR",
                        message:
                            "Something went wrong. Please try again after some time.",
                    })
                )
            );
    }

    createSession(
        phoneNumber: string,
        countryCode: string,
        deviceId: string,
        deviceMake: string,
        deviceModel: string
    ): Observable<any> {
        const headers = new HttpHeaders({
            Authorization: `Basic ODI4YWExZmMtYjk3Zi00Y2E0LTkzYzQtOTg2OGQyNjJkMzgyOkJnOExCZ01KREFnS0JnWUpCQTBMQkE=`,
            "Content-Type": "application/x-www-form-urlencoded",
        });

        const payload = new HttpParams({
            fromObject: {
                phoneNumber,
                countryCode,
                deviceId,
                deviceMake,
                deviceModel,
            },
        });

        return this.http
            .post<AuthResponse>(`/iam/beta/sessions`, payload.toString(), {
                headers,
                observe: "response",
            })
            .pipe(retryWhen(this.onError));
    }

    createAccount(
        fn: string,
        ln: string,
        email: string,
        pn: string,
        cc: string
    ): Observable<User> {
        const headers = new HttpHeaders({
            Authorization: `Basic ODI4YWExZmMtYjk3Zi00Y2E0LTkzYzQtOTg2OGQyNjJkMzgyOkJnOExCZ01KREFnS0JnWUpCQTBMQkE=`,
        });

        const payload = {
            firstName: fn,
            lastName: ln,
            email: email,
            phoneNumber: pn,
            countryCode: cc,
        };

        return this.http.post(`/iam/beta/users`, payload, { headers }).pipe(
            retryWhen(this.onError),
            map((resp) => resp as User)
        );
    }

    login(phoneNumber: string, countryCode: string, password: string) {
        const headers = new HttpHeaders({
            Authorization: `Basic ODI4YWExZmMtYjk3Zi00Y2E0LTkzYzQtOTg2OGQyNjJkMzgyOkJnOExCZ01KREFnS0JnWUpCQTBMQkE=`,
            "Content-Type": "application/x-www-form-urlencoded",
        });

        const payload = new HttpParams({
            fromObject: {
                phoneNumber,
                countryCode,
                password,
                grantType: "password",
            },
        });

        return this.http
            .post(`/iam/beta/tokens`, payload.toString(), { headers })
            .pipe(retryWhen(this.onError));
    }

    createOrg(accessToken: string, payload: any): Observable<Organization> {
        const headers = new HttpHeaders({
            Authorization: `Bearer ${accessToken}`,
        });

        return this.http.post(`iam/beta/orgs`, payload, { headers }).pipe(
            retryWhen(this.onError),
            map((resp) => resp as Organization)
        );
    }

    getOrgs(
        accessToken: string,
        params: HttpParams
    ): Observable<ListingResponse<Organization>> {
        const headers = new HttpHeaders({
            Authorization: `Bearer ${accessToken}`,
        });

        return this.http
            .get(`/iam/beta/orgs`, { params, headers })
            .pipe(
                map((resp: any) => {
                    return resp as ListingResponse<Organization>;
                })
            )
            .pipe(retryWhen(this.onError));
    }

    getHubs(
        accessToken: string,
        orgId: string,
        params: HttpParams
    ): Observable<ListingResponse<Hub>> {
        const headers = new HttpHeaders({
            Authorization: `Bearer ${accessToken}`,
        });

        return this.http
            .get(`/beta/orgs/${orgId}/hubs`, { params, headers })
            .pipe(
                map((resp: any) => {
                    return resp as ListingResponse<Hub>;
                })
            )
            .pipe(retryWhen(this.onError));
    }

    createHub(
        orgId: string,
        accessToken: string,
        payload: any
    ): Observable<Hub> {
        const headers = new HttpHeaders({
            Authorization: `Bearer ${accessToken}`,
        });

        return this.http
            .post(`/beta/orgs/${orgId}/hubs`, payload, { headers })
            .pipe(
                retryWhen(this.onError),
                map((resp) => resp as Hub)
            );
    }

    createCategory(
        orgId: string,
        accessToken: string,
        payload: Object
    ): Observable<Category> {
        const headers = new HttpHeaders({
            Authorization: `Bearer ${accessToken}`,
        });

        return this.http
            .post(`/beta/orgs/${orgId}/categories`, payload, { headers })
            .pipe(
                retryWhen(this.onError),
                map((resp) => resp as Category)
            );
    }

    deleteCategory(categoryId: string, accessToken: string): Observable<any> {
        const headers = new HttpHeaders({
            Authorization: `Bearer ${accessToken}`,
        });

        return this.http
            .delete(`/beta/categories/${categoryId}`, { headers })
            .pipe(retryWhen(this.onError));
    }

    updateCategory(
        categoryId: string,
        accessToken: string,
        payload: Object
    ): Observable<Category> {
        const headers = new HttpHeaders({
            Authorization: `Bearer ${accessToken}`,
        });

        return this.http
            .patch(`/beta/categories/${categoryId}`, payload, { headers })
            .pipe(
                retryWhen(this.onError),
                map((resp) => resp as Category)
            );
    }

    getCategories(
        orgId: string,
        accessToken: string,
        params: HttpParams
    ): Observable<ListingResponse<Category>> {
        const headers = new HttpHeaders({
            Authorization: `Bearer ${accessToken}`,
        });

        return this.http
            .get(`/beta/orgs/${orgId}/categories`, { params, headers })
            .pipe(
                map((resp: any) => {
                    return resp as ListingResponse<Category>;
                })
            )
            .pipe(retryWhen(this.onError));
    }

    getRateCards(
        hubId: string,
        categoryId: string,
        accessToken: string,
        params: HttpParams
    ): Observable<ListingResponse<RateCard>> {
        const headers = new HttpHeaders({
            Authorization: `Bearer ${accessToken}`,
        });

        return this.http
            .get(`/beta/hubs/${hubId}/categories/${categoryId}/rateCards`, {
                params,
                headers,
            })
            .pipe(
                map((resp: any) => {
                    return resp as ListingResponse<RateCard>;
                })
            )
            .pipe(retryWhen(this.onError));
    }

    getPackages(
        hubId: string,
        accessToken: string,
        params: HttpParams
    ): Observable<ListingResponse<Package>> {
        const headers = new HttpHeaders({
            Authorization: `Bearer ${accessToken}`,
        });

        return this.http
            .get(`/beta/hubs/${hubId}/packages`, { params, headers })
            .pipe(
                map((resp: any) => {
                    return resp as ListingResponse<Package>;
                })
            )
            .pipe(retryWhen(this.onError));
    }

    getProducts(
        hubId: string,
        accessToken: string,
        params: HttpParams
    ): Observable<ListingResponse<VehicleSummary>> {
        const headers = new HttpHeaders({
            Authorization: `Bearer ${accessToken}`,
        });

        return this.http
            .get(`/beta/hubs/${hubId}/products`, { params, headers })
            .pipe(
                map((resp: any) => {
                    return resp as ListingResponse<VehicleSummary>;
                })
            )
            .pipe(retryWhen(this.onError));
    }

    createProduct(
        hubId: string,
        accessToken: string,
        payload: Object
    ): Observable<Vehicle> {
        const headers = new HttpHeaders({
            Authorization: `Bearer ${accessToken}`,
        });

        return this.http
            .post(`/beta/hubs/${hubId}/productListings`, payload, { headers })
            .pipe(
                retryWhen(this.onError),
                map((resp) => resp as Vehicle)
            );
    }

    updateProduct(
        productId: string,
        accessToken: string,
        payload: Object
    ): Observable<Vehicle> {
        const headers = new HttpHeaders({
            Authorization: `Bearer ${accessToken}`,
        });

        return this.http
            .patch(`/beta/products/${productId}`, payload, { headers })
            .pipe(
                retryWhen(this.onError),
                map((resp) => resp as Vehicle)
            );
    }

    createVehicle(
        productId: string,
        accessToken: string,
        payload
    ): Observable<any> {
        const headers = new HttpHeaders({
            Authorization: `Bearer ${accessToken}`,
        });

        return this.http
            .post(`/beta/products/${productId}/vehicles`, payload, { headers })
            .pipe(retryWhen(this.onError));
    }

    getVehicle(
        vehicleId: string,
        accessToken: string,
        params: HttpParams
    ): Observable<Plate> {
        const headers = new HttpHeaders({
            Authorization: `Bearer ${accessToken}`,
        });

        return this.http
            .get(`/beta/vehicles/${vehicleId}`, { headers })
            .pipe(
                map((resp: any) => {
                    return resp as Plate;
                })
            )
            .pipe(retryWhen(this.onError));
    }
    deleteProduct(
        hubId: string,
        productId: string,
        accessToken: string,
        params?: HttpParams
    ): Observable<any> {
        const headers = new HttpHeaders({
            Authorization: `Bearer ${accessToken}`,
        });

        return this.http
            .delete(`beta/hubs/${hubId}/products/${productId}`, {
                headers,
                params,
            })
            .pipe(
                retryWhen(this.onError),
                map((resp) => resp as any)
            );
    }

    updateVehicle(
        vehicleId: string,
        accessToken: string,
        payload
    ): Observable<any> {
        const headers = new HttpHeaders({
            Authorization: `Bearer ${accessToken}`,
        });

        return this.http
            .patch(`/beta/vehicles/${vehicleId}`, payload, { headers })
            .pipe(retryWhen(this.onError));
    }

    getProductVehicles(
        productId: string,
        accessToken: string,
        params: HttpParams
    ): Observable<ListingResponse<Plate>> {
        const headers = new HttpHeaders({
            Authorization: `Bearer ${accessToken}`,
        });

        return this.http
            .get(`/beta/products/${productId}/vehicles`, { params, headers })
            .pipe(
                map((resp: any) => {
                    return resp as ListingResponse<Plate>;
                })
            )
            .pipe(retryWhen(this.onError));
    }

    getProductImages(
        productId: string,
        accessToken: string,
        params: HttpParams
    ): Observable<ListingResponse<ProductImage>> {
        const headers = new HttpHeaders({
            Authorization: `Bearer ${accessToken}`,
        });

        return this.http
            .get(`/beta/products/${productId}/images`, { params, headers })
            .pipe(
                map((resp: any) => {
                    return resp as ListingResponse<ProductImage>;
                })
            )
            .pipe(retryWhen(this.onError));
    }

    getBrands(
        accessToken: string,
        params: HttpParams
    ): Observable<ListingResponse<Brand>> {
        const headers = new HttpHeaders({
            Authorization: `Bearer ${accessToken}`,
        });

        return this.http
            .get(`/beta/brands`, { params, headers })
            .pipe(
                map((resp: any) => {
                    return resp as ListingResponse<Brand>;
                })
            )
            .pipe(retryWhen(this.onError));
    }

    getProductMakes(
        accessToken: string,
        params: HttpParams
    ): Observable<ListingResponse<BrandDescriptor>> {
        const headers = new HttpHeaders({
            Authorization: `Bearer ${accessToken}`,
        });

        return this.http
            .get(`/beta/makes`, { params, headers })
            .pipe(
                map((resp: any) => {
                    return resp as ListingResponse<BrandDescriptor>;
                })
            )
            .pipe(retryWhen(this.onError));
    }

    getProductModels(
        accessToken: string,
        params: HttpParams
    ): Observable<ListingResponse<BrandDescriptor>> {
        const headers = new HttpHeaders({
            Authorization: `Bearer ${accessToken}`,
        });

        return this.http
            .get(`/beta/models`, { params, headers })
            .pipe(
                map((resp: any) => {
                    return resp as ListingResponse<BrandDescriptor>;
                })
            )
            .pipe(retryWhen(this.onError));
    }

    getProductVariants(
        accessToken: string,
        params: HttpParams
    ): Observable<ListingResponse<BrandDescriptor>> {
        const headers = new HttpHeaders({
            Authorization: `Bearer ${accessToken}`,
        });

        return this.http
            .get(`/beta/variants`, { params, headers })
            .pipe(
                map((resp: any) => {
                    return resp as ListingResponse<BrandDescriptor>;
                })
            )
            .pipe(retryWhen(this.onError));
    }

    createUser(orgId: string, accessToken: string, payload): Observable<User> {
        const headers = new HttpHeaders({
            Authorization: `Bearer ${accessToken}`,
        });

        return this.http
            .post(`/beta/orgs/${orgId}/profiles`, payload, { headers })
            .pipe(
                retryWhen(this.onError),
                map((resp) => resp as User)
            );
    }

    updateUser(
        profileId: string,
        orgId: string,
        accessToken: string,
        payload
    ): Observable<User> {
        const headers = new HttpHeaders({
            Authorization: `Bearer ${accessToken}`,
        });

        return this.http
            .put(`/beta/profiles/${profileId}`, payload, { headers })
            .pipe(
                retryWhen(this.onError),
                map((resp) => resp as User)
            );
    }

    deleteProfile(profileId: string, accessToken: string): Observable<any> {
        const headers = new HttpHeaders({
            Authorization: `Bearer ${accessToken}`,
        });

        return this.http
            .delete(`/beta/profiles/${profileId}`, { headers })
            .pipe(
                retryWhen(this.onError),
                map((resp) => resp as any)
            );
    }

    getUsers(
        orgId: string,
        accessToken: string,
        params: HttpParams
    ): Observable<ListingResponse<User>> {
        const headers = new HttpHeaders({
            Authorization: `Bearer ${accessToken}`,
        });

        return this.http
            .get(`/beta/orgs/${orgId}/profiles`, { params, headers })
            .pipe(
                map((resp: any) => {
                    return resp as ListingResponse<User>;
                })
            )
            .pipe(retryWhen(this.onError));
    }

    createImage(accessToken: string, payload: any): Observable<any> {
        const headers = new HttpHeaders({
            Authorization: `Bearer ${accessToken}`,
        });

        const req = new HttpRequest("POST", "/beta/images", payload, {
            headers: headers,
            reportProgress: true,
        });
        return this.http.request(req).pipe(retryWhen(this.onError));
    }
    corporateDefaultImage(accessToken: string, payload: any): Observable<any> {
        const headers = new HttpHeaders({
            Authorization: `Bearer ${accessToken}`,
        });

        const req = new HttpRequest("POST", "/beta/whatsappImage", payload, {
            headers: headers,
            reportProgress: true,
        });
        return this.http.request(req).pipe(retryWhen(this.onError));
    }
    getWhatsAppImage(
        accessToken: string
    ): Observable<ImageResponse<ImageDescriptor>> {
        const headers = new HttpHeaders({
            Authorization: `Bearer ${accessToken}`,
        });
        return this.http
            .get(`/beta/whatsappImage`, { headers })
            .pipe(
                map((resp: any) => {
                    return resp as ImageResponse<ImageDescriptor>;
                })
            )
            .pipe(retryWhen(this.onError));
    }

    addImages(
        productId: string,
        accessToken: string,
        payload: any
    ): Observable<any> {
        const headers = new HttpHeaders({
            Authorization: `Bearer ${accessToken}`,
        });

        return this.http
            .post(`/beta/products/${productId}/images`, payload, { headers })
            .pipe(retryWhen(this.onError));
    }

    removeImage(
        productId: string,
        imageId: string,
        accessToken: string
    ): Observable<any> {
        const headers = new HttpHeaders({
            Authorization: `Bearer ${accessToken}`,
        });

        return this.http
            .delete(`/beta/products/${productId}/images/${imageId}`, {
                headers,
            })
            .pipe(retryWhen(this.onError));
    }

    getRideRequests(
        hubId: string,
        accessToken: string,
        params: HttpParams
    ): Observable<ListingResponse<RideRequest>> {
        const headers = new HttpHeaders({
            Authorization: `Bearer ${accessToken}`,
        });

        return this.http
            .get(`/beta/hubs/${hubId}/rideRequests`, { params, headers })
            .pipe(
                map((resp: any) => {
                    return resp as ListingResponse<RideRequest>;
                })
            )
            .pipe(retryWhen(this.onError));
    }

    getRideRequest(
        rideRequestId: string,
        accessToken: string,
        params: HttpParams
    ): Observable<RideRequest> {
        const headers = new HttpHeaders({
            Authorization: `Bearer ${accessToken}`,
        });

        return this.http
            .get(`/beta/rideRequests/${rideRequestId}`, { params, headers })
            .pipe(
                map((resp: any) => {
                    return resp as RideRequest;
                })
            )
            .pipe(retryWhen(this.onError));
    }
    getUserdetails(
        profileId: string,
        accessToken: string,
        params: HttpParams
    ): Observable<ListingResponse<User>> {
        const headers = new HttpHeaders({
            Authorization: `Bearer ${accessToken}`,
        });

        return this.http
            .get(`/beta/driverRideList/${profileId}`, { params, headers })
            .pipe(
                map((resp: any) => {
                    return resp as ListingResponse<User>;
                })
            )
            .pipe(retryWhen(this.onError));
    }

    getActiveRateCard(
        productId: string,
        categoryId: string,
        accessToken: string,
        params: HttpParams
    ): Observable<RateCard> {
        const headers = new HttpHeaders({
            Authorization: `Bearer ${accessToken}`,
        });

        return this.http
            .get(`/beta/products/${productId}/rateCard/${categoryId}`, {
                params,
                headers,
            })
            .pipe(
                map((resp: any) => {
                    return resp as RateCard;
                })
            )
            .pipe(retryWhen(this.onError));
    }

    createFareEstimate(
        productId: string,
        accessToken: string,
        payload: any
    ): Observable<FareEstimate> {
        const headers = new HttpHeaders({
            Authorization: `Bearer ${accessToken}`,
        });

        return this.http
            .post(`/beta/products/${productId}/estimates`, payload, {
                headers: headers,
            })
            .pipe(
                map((resp: any) => {
                    return resp as FareEstimate;
                })
            )
            .pipe(retryWhen(this.onError));
    }

    getAvailableVehicles(
        hubId: string,
        accessToken: string,
        params: HttpParams
    ): Observable<ListingResponse<Plate>> {
        const headers = new HttpHeaders({
            Authorization: `Bearer ${accessToken}`,
        });

        return this.http
            .get(`/beta/hubs/${hubId}/vehicles`, { params, headers })
            .pipe(
                map((resp: any) => {
                    return resp;
                })
            )
            .pipe(retryWhen(this.onError));
    }

    createRideRequest(
        hubId: string,
        accessToken: string,
        payload: any
    ): Observable<RideRequest> {
        const headers = new HttpHeaders({
            Authorization: `Bearer ${accessToken}`,
        });

        return this.http
            .post(`/beta/hubs/${hubId}/rideRequests`, payload, { headers })
            .pipe(map((resp) => resp as RideRequest))
            .pipe(retryWhen(this.onError));
    }

    updateRideRequestState(
        rideRequestId: string,
        accessToken: string,
        payload: any
    ): Observable<void> {
        const headers = new HttpHeaders({
            Authorization: `Bearer ${accessToken}`,
        });

        return this.http
            .patch(`/beta/rideRequests/${rideRequestId}`, payload, { headers })
            .pipe(
                map((resp: any) => {
                    return resp;
                })
            )
            .pipe(retryWhen(this.onError));
    }

    pushRideRequestToDrivers(
        rideRequestId: string,
        accessToken: string,
        payload: any
    ): Observable<void> {
        const headers = new HttpHeaders({
            Authorization: `Bearer ${accessToken}`,
        });

        return this.http
            .post(
                `/beta/rideRequests/${rideRequestId}/pushToDrivers`,
                payload,
                { headers }
            )
            .pipe(
                map((resp: any) => {
                    return resp;
                })
            )
            .pipe(retryWhen(this.onError));
    }

    updateRideRequestProduct(
        rideRequestId: string,
        accessToken: string,
        payload: any
    ): Observable<void> {
        const headers = new HttpHeaders({
            Authorization: `Bearer ${accessToken}`,
        });

        return this.http
            .put(`/beta/rideRequests/${rideRequestId}/product`, payload, {
                headers,
            })
            .pipe(
                map((resp: any) => {
                    return resp;
                })
            )
            .pipe(retryWhen(this.onError));
    }

    updateFCMToken(accessToken: string, payload: any): Observable<void> {
        const headers = new HttpHeaders({
            Authorization: `Bearer ${accessToken}`,
        });

        return this.http
            .post(`/beta/updateFcmToken`, payload, { headers })
            .pipe(
                map((resp: any) => {
                    return resp;
                })
            )
            .pipe(retryWhen(this.onError));
    }

    createRateCard(
        hubId: string,
        categoryId: string,
        accessToken: string,
        payload: any
    ): Observable<RateCard> {
        const headers = new HttpHeaders({
            Authorization: `Bearer ${accessToken}`,
        });

        return this.http
            .post(
                `/beta/hubs/${hubId}/categories/${categoryId}/rateCards`,
                payload,
                { headers }
            )
            .pipe(
                map((resp: any) => {
                    return resp as RateCard;
                })
            )
            .pipe(retryWhen(this.onError));
    }

    saveRateCard(
        rateCardId: string,
        accessToken: string,
        payload: any
    ): Observable<RateCard> {
        const headers = new HttpHeaders({
            Authorization: `Bearer ${accessToken}`,
        });

        return this.http
            .patch(`/beta/rateCards/${rateCardId}`, payload, { headers })
            .pipe(
                map((resp: any) => {
                    return resp as RateCard;
                })
            )
            .pipe(retryWhen(this.onError));
    }

    getCities(
        accessToken: string,
        params: HttpParams
    ): Observable<ListingResponse<City>> {
        const headers = new HttpHeaders({
            Authorization: `Bearer ${accessToken}`,
        });

        return this.http
            .get(`/beta/cities`, { params, headers })
            .pipe(
                map((resp: any) => {
                    return resp as ListingResponse<City>;
                })
            )
            .pipe(retryWhen(this.onError));
    }

    getDriverLocation(rideId: string): Observable<TrackingInfo> {
        return this.http
            .get(`/beta/driver/location/${rideId}`)
            .pipe(
                map((resp: any) => {
                    return resp as TrackingInfo;
                })
            )
            .pipe(
                retryWhen((errors: Observable<any>) => {
                    return errors.pipe(
                        flatMap((e: any) => {
                            const errorCodes = [
                                "RIDE_EXPIRED",
                                "NO_DRIVER_ASSIGNED",
                                "CANCELLED_OR_FAILED",
                                "COMPLETED",
                                "NO_LOCATION_AVAILABLE",
                            ];
                            if (errorCodes.indexOf(e.error.errorCode) !== -1) {
                                return throwError(e);
                            } else {
                                return this.onError(errors);
                            }
                        })
                    );
                })
            );
    }

    getRideSummary(
        shortId: string
    ): Observable<{ rideId: string; pickupTime: string }> {
        return this.http
            .get(`/beta/rides/tracking/${shortId}`)
            .pipe(
                map((resp: any) => {
                    return resp as { rideId: string; pickupTime: string };
                })
            )
            .pipe(
                retryWhen((errors: Observable<any>) => {
                    return errors.pipe(
                        flatMap((e: any) => {
                            const errorCodes = [
                                "RESOURCE_NOT_FOUND",
                                "RIDE_EXPIRED",
                                "NO_DRIVER_ASSIGNED",
                                "CANCELLED_OR_FAILED",
                                "COMPLETED",
                                "NO_LOCATION_AVAILABLE",
                            ];
                            if (errorCodes.indexOf(e.error.errorCode) !== -1) {
                                return throwError(e);
                            } else {
                                return this.onError(errors);
                            }
                        })
                    );
                })
            );
    }

    getNotifications(
        accessToken: string,
        params: HttpParams
    ): Observable<ListingResponse<SourceNotification>> {
        const headers = new HttpHeaders({
            Authorization: `Bearer ${accessToken}`,
        });

        return this.http
            .get(`/beta/notifications`, { params, headers })
            .pipe(
                map((resp: any) => {
                    resp.items = resp.items.map((item) => {
                        return new SourceNotification(item);
                    });
                    return resp as ListingResponse<SourceNotification>;
                })
            )
            .pipe(retryWhen(this.onError));
    }

    updateNotificationReadStatus(
        accessToken: string,
        notId: string,
        payload: any
    ): Observable<any> {
        const headers = new HttpHeaders({
            Authorization: `Bearer ${accessToken}`,
        });

        return this.http
            .patch(`/beta/notifications/${notId}`, payload, { headers })
            .pipe(
                map((resp: any) => {
                    return resp;
                })
            )
            .pipe(retryWhen(this.onError));
    }

    getRideEvents(
        accessToken: string,
        hubId: string,
        params: HttpParams
    ): Observable<{ items: RideEvent[] }> {
        const headers = new HttpHeaders({
            Authorization: `Bearer ${accessToken}`,
        });

        return this.http
            .get(`/beta/hubs/${hubId}/rideEvents`, { params, headers })
            .pipe(
                map((resp: any) => {
                    return resp as any;
                })
            )
            .pipe(retryWhen(this.onError));
    }

    getUserProfile(accessToken: string, userId: string): Observable<User> {
        const headers = new HttpHeaders({
            Authorization: `Bearer ${accessToken}`,
        });

        return this.http
            .get(`/iam/beta/users/${userId}`, { headers })
            .pipe(
                map((resp: any) => {
                    return resp as User;
                })
            )
            .pipe(retryWhen(this.onError));
    }

    addPhoneNumber(
        userId: string,
        accessToken: string,
        payload: any
    ): Observable<{ success: boolean; message: string; processId: string }> {
        const headers = new HttpHeaders({
            Authorization: `Bearer ${accessToken}`,
        });

        return this.http
            .post(`/iam/beta/users/${userId}/phones`, payload, { headers })
            .pipe(
                map((resp: any) => {
                    return resp;
                })
            )
            .pipe(retryWhen(this.onError));
    }

    verifyPhoneNumber(
        userId: string,
        accessToken: string,
        payload: any
    ): Observable<{ success: boolean; message: string }> {
        const headers = new HttpHeaders({
            Authorization: `Bearer ${accessToken}`,
        });

        return this.http
            .post(`/iam/beta/users/${userId}/phones/verify`, payload, {
                headers,
            })
            .pipe(
                map((resp: any) => {
                    return resp;
                })
            )
            .pipe(retryWhen(this.onError));
    }

    updatePrimaryPhoneNumber(
        userId: string,
        accessToken: string,
        payload: { phoneId: string }
    ): Observable<{ success: boolean; message: string }> {
        const headers = new HttpHeaders({
            Authorization: `Bearer ${accessToken}`,
        });

        return this.http
            .post(`/iam/beta/users/${userId}/phones/setPrimary`, payload, {
                headers,
            })
            .pipe(
                map((resp: any) => {
                    return resp;
                })
            )
            .pipe(retryWhen(this.onError));
    }

    updatePhoneNumberKind(
        userId: string,
        accessToken: string,
        payload: { phoneId: string; kind: PhoneKind }
    ): Observable<{ success: boolean; message: string }> {
        const headers = new HttpHeaders({
            Authorization: `Bearer ${accessToken}`,
        });

        return this.http
            .post(`/iam/beta/users/${userId}/phones/setPhoneKind`, payload, {
                headers,
            })
            .pipe(
                map((resp: any) => {
                    return resp;
                })
            )
            .pipe(retryWhen(this.onError));
    }

    updatePhoneVisibility(
        userId: string,
        accessToken: string,
        payload: { phoneId: string; visibility: "public" | "private" }
    ): Observable<{ success: boolean; message: string }> {
        const headers = new HttpHeaders({
            Authorization: `Bearer ${accessToken}`,
        });

        return this.http
            .post(`/iam/beta/users/${userId}/phones/setVisibility`, payload, {
                headers,
            })
            .pipe(
                map((resp: any) => {
                    return resp;
                })
            )
            .pipe(retryWhen(this.onError));
    }

    addEmail(
        userId: string,
        accessToken: string,
        payload: any
    ): Observable<{ success: boolean; message: string; processId: string }> {
        const headers = new HttpHeaders({
            Authorization: `Bearer ${accessToken}`,
        });

        return this.http
            .post(`/iam/beta/users/${userId}/emails`, payload, { headers })
            .pipe(
                map((resp: any) => {
                    return resp;
                })
            )
            .pipe(retryWhen(this.onError));
    }

    verifyEmail(
        userId: string,
        accessToken: string,
        payload: any
    ): Observable<{ success: boolean; message: string }> {
        const headers = new HttpHeaders({
            Authorization: `Bearer ${accessToken}`,
        });

        return this.http
            .post(`/iam/beta/users/${userId}/emails/verify`, payload, {
                headers,
            })
            .pipe(
                map((resp: any) => {
                    return resp;
                })
            )
            .pipe(retryWhen(this.onError));
    }

    updatePrimaryEmail(
        userId: string,
        accessToken: string,
        payload: { emailId: string }
    ): Observable<{ success: boolean; message: string }> {
        const headers = new HttpHeaders({
            Authorization: `Bearer ${accessToken}`,
        });

        return this.http
            .post(`/iam/beta/users/${userId}/emails/setPrimary`, payload, {
                headers,
            })
            .pipe(
                map((resp: any) => {
                    return resp;
                })
            )
            .pipe(retryWhen(this.onError));
    }

    updateEmailKind(
        userId: string,
        accessToken: string,
        payload: { emailId: string; kind: EmailKind }
    ): Observable<{ success: boolean; message: string }> {
        const headers = new HttpHeaders({
            Authorization: `Bearer ${accessToken}`,
        });

        return this.http
            .post(`/iam/beta/users/${userId}/emails/setEmailKind`, payload, {
                headers,
            })
            .pipe(
                map((resp: any) => {
                    return resp;
                })
            )
            .pipe(retryWhen(this.onError));
    }

    updateEmailVisibility(
        userId: string,
        accessToken: string,
        payload: { emailId: string; visibility: "public" | "private" }
    ): Observable<{ success: boolean; message: string }> {
        const headers = new HttpHeaders({
            Authorization: `Bearer ${accessToken}`,
        });

        return this.http
            .post(`/iam/beta/users/${userId}/emails/setVisibility`, payload, {
                headers,
            })
            .pipe(
                map((resp: any) => {
                    return resp;
                })
            )
            .pipe(retryWhen(this.onError));
    }

    updateRideRequest(
        rideRequestId: string,
        accessToken: string,
        payload: any
    ): Observable<void> {
        const headers = new HttpHeaders({
            Authorization: `Bearer ${accessToken}`,
        });

        return this.http
            .put(`/beta/rideRequests/${rideRequestId}`, payload, { headers })
            .pipe(
                map((resp: any) => {
                    return resp;
                })
            )
            .pipe(retryWhen(this.onError));
    }

    completeTripsheet(
        rideRequestId: string,
        accessToken: string,
        payload: any
    ): Observable<TripSheet> {
        const headers = new HttpHeaders({
            Authorization: `Bearer ${accessToken}`,
        });

        return this.http
            .post(`/beta/rideRequests/${rideRequestId}/endTrip`, payload, {
                headers,
            })
            .pipe(
                map((resp: any) => {
                    return resp as TripSheet;
                })
            )
            .pipe(retryWhen(this.onError));
    }
    completeTripsheet1(
        rideRequestId: string,
        accessToken: string,
        payload: any
    ): Observable<TripSheet> {
        const headers = new HttpHeaders({
            Authorization: `Bearer ${accessToken}`,
        });

        return this.http
            .post(`/beta/rideRequests/${rideRequestId}/complete`, payload, {
                headers,
            })
            .pipe(map((resp) => resp as TripSheet));
    }

    getPaymentDetails(
        rideRequestId: string,
        accessToken: string,
        params: HttpParams
    ): Observable<PaymentDetails> {
        const headers = new HttpHeaders({
            Authorization: `Bearer ${accessToken}`,
        });

        return this.http
            .get(`/beta/rideRequests/${rideRequestId}/payments`, {
                params,
                headers,
            })
            .pipe(
                map((resp: any) => {
                    return resp as PaymentDetails;
                })
            )
            .pipe(retryWhen(this.onError));
    }

    capturePayment(
        rideRequestId: string,
        accessToken: string,
        payload: any
    ): Observable<void> {
        const headers = new HttpHeaders({
            Authorization: `Bearer ${accessToken}`,
        });

        return this.http
            .post(`/beta/rideRequests/${rideRequestId}/payments`, payload, {
                headers,
            })
            .pipe(
                map((resp: any) => {
                    return resp;
                })
            )
            .pipe(retryWhen(this.onError));
    }

    refreshPaymentStatus(
        rideRequestId: string,
        paymentId: string,
        accessToken: string,
        payload: any
    ): Observable<{ success: boolean; updated: boolean }> {
        const headers = new HttpHeaders({
            Authorization: `Bearer ${accessToken}`,
        });

        return this.http
            .post(
                `beta/rideRequests/${rideRequestId}/payments/${paymentId}/refreshStatus`,
                payload,
                { headers }
            )
            .pipe(
                map((resp: any) => {
                    return resp;
                })
            )
            .pipe(retryWhen(this.onError));
    }

    getRides(
        hubId: string,
        accessToken: string,
        params: HttpParams
    ): Observable<ListingResponse<RideRequest>> {
        const headers = new HttpHeaders({
            Authorization: `Bearer ${accessToken}`,
        });

        return this.http
            .get(`/beta/hubs/${hubId}/rides`, { params, headers })
            .pipe(
                map((resp: any) => {
                    return resp as ListingResponse<RideRequest>;
                })
            )
            .pipe(retryWhen(this.onError));
    }

    getOrgRides(
        orgId: string,
        accessToken: string,
        params: HttpParams
    ): Observable<ListingResponse<RideRequest>> {
        const headers = new HttpHeaders({
            Authorization: `Bearer ${accessToken}`,
        });

        return this.http
            .get(`/beta/orgs/${orgId}/rides`, { params, headers })
            .pipe(
                map((resp: any) => {
                    return resp as ListingResponse<RideRequest>;
                })
            )
            .pipe(retryWhen(this.onError));
    }

    createSubCategory(
        orgId: string,
        categoryId: string,
        accessToken: string,
        payload: Object
    ): Observable<Category> {
        const headers = new HttpHeaders({
            Authorization: `Bearer ${accessToken}`,
        });

        return this.http
            .post(
                `/beta/orgs/${orgId}/categories/${categoryId}/subCategories`,
                payload,
                { headers }
            )
            .pipe(
                retryWhen(this.onError),
                map((resp) => resp as Category)
            );
    }

    updateSubCategory(
        categoryId: string,
        subCategoryId: string,
        accessToken: string,
        payload: Object
    ): Observable<Category> {
        const headers = new HttpHeaders({
            Authorization: `Bearer ${accessToken}`,
        });

        return this.http
            .patch(
                `/beta/categories/${categoryId}/subCategories/${subCategoryId}`,
                payload,
                { headers }
            )
            .pipe(
                retryWhen(this.onError),
                map((resp) => resp as Category)
            );
    }

    getCategoriesIncludingSubCategories(
        orgId: string,
        accessToken: string,
        params: HttpParams
    ): Observable<ListingResponse<Category>> {
        const headers = new HttpHeaders({
            Authorization: `Bearer ${accessToken}`,
        });

        return this.http
            .get(`/beta/orgs/${orgId}/allCategories`, { params, headers })
            .pipe(
                map((resp: any) => {
                    return resp as ListingResponse<Category>;
                })
            )
            .pipe(retryWhen(this.onError));
    }

    getRideEnquiries(
        hubId: string,
        accessToken: string,
        params: HttpParams
    ): Observable<ListingResponse<RideEnquiry>> {
        const headers = new HttpHeaders({
            Authorization: `Bearer ${accessToken}`,
        });

        return this.http
            .get(`/beta/hubs/${hubId}/rideEnquiries`, { params, headers })
            .pipe(
                map((resp: any) => {
                    return resp as ListingResponse<RideEnquiry>;
                })
            )
            .pipe(retryWhen(this.onError));
    }
    getDailyrideEnquiries(
        accessToken: string,
        params: HttpParams
    ): Observable<DailyCommuteResponse<DailyCommute>> {
        const headers = new HttpHeaders({
            Authorization: `Bearer ${accessToken}`,
        });

        return this.http
            .get<DailyCommuteResponse<DailyCommute>>(
                `/beta/customer/dailyCommute`,
                { params, headers }
            )
            .pipe(
                retryWhen(this.onError) // Handle retry logic
            );
    }
    deleteDailyrideEnquiries(id: string, accessToken: string): Observable<any> {
        const headers = new HttpHeaders({
            Authorization: `Bearer ${accessToken}`,
        });
        return this.http
            .delete(`/beta/customer/dailyCommute/${id}`, { headers })
            .pipe(
                retryWhen(this.onError),
                map((resp) => resp as any)
            );
    }
    toggleCommuteEnable(
        id: string,
        accessToken: string,
        enable: boolean
    ): Observable<any> {
        const headers = new HttpHeaders({
            Authorization: `Bearer ${accessToken}`,
        });

        return this.http
            .put(
                `/beta/customer/dailyCommute/${id}/toggleEnable`,
                { enable },
                { headers }
            )
            .pipe(
                retryWhen(this.onError),
                map((resp) => resp as any)
            );
    }
    getDailyridedetails(
        accessToken: string,
        id: string
    ): Observable<DailyCommuteResponse<DailyCommute>> {
        const headers = new HttpHeaders({
            Authorization: `Bearer ${accessToken}`,
        });

        return this.http
            .get<DailyCommuteResponse<DailyCommute>>(
                `/beta/customer/dailyCommute/${id}`,
                { headers }
            )
            .pipe(retryWhen(this.onError));
    }

    updateRideEnquiryResolution(
        enquiryId: string,
        accessToken: string,
        payload: any
    ): Observable<{ success: boolean; message: string }> {
        const headers = new HttpHeaders({
            Authorization: `Bearer ${accessToken}`,
        });

        return this.http
            .patch(`/beta/rideEnquiries/${enquiryId}`, payload, { headers })
            .pipe(
                map((resp: any) => {
                    return resp;
                })
            )
            .pipe(retryWhen(this.onError));
    }

    getCorporateCustomers(
        accessToken: string,
        orgId: string,
        params: HttpParams
    ): Observable<ListingResponse<User>> {
        const headers = new HttpHeaders({
            Authorization: `Bearer ${accessToken}`,
        });

        return this.http
            .get(`/beta/orgs/${orgId}/accounts/corporates`, { params, headers })
            .pipe(
                map((resp: any) => {
                    return resp as ListingResponse<User>;
                })
            )
            .pipe(retryWhen(this.onError));
    }

    getBusinesses(
        accessToken: string,
        orgId: string,
        params: HttpParams
    ): Observable<ListingResponse<Business>> {
        const headers = new HttpHeaders({
            Authorization: `Bearer ${accessToken}`,
        });

        return this.http
            .get(`/beta/orgs/${orgId}/accounts/businesses`, { params, headers })
            .pipe(
                map((resp: any) => {
                    return resp as ListingResponse<Business>;
                })
            )
            .pipe(retryWhen(this.onError));
    }

    getCategoryRateCards(
        hubId: string,
        accessToken: string,
        params: HttpParams
    ): Observable<ListingResponse<RateCard>> {
        const headers = new HttpHeaders({
            Authorization: `Bearer ${accessToken}`,
        });

        return this.http
            .get(`/beta/hubs/${hubId}/rateCards`, { params, headers })
            .pipe(
                map((resp: any) => {
                    return resp as ListingResponse<RateCard>;
                })
            )
            .pipe(retryWhen(this.onError));
    }

    createCategoryRateCard(
        hubId: string,
        accessToken: string,
        payload: Object
    ): Observable<RateCard> {
        const headers = new HttpHeaders({
            Authorization: `Bearer ${accessToken}`,
        });

        return this.http
            .post(`/beta/hubs/${hubId}/rateCards`, payload, { headers })
            .pipe(retryWhen(this.onError))
            .pipe(
                map((resp: any) => {
                    return resp.rateCard as RateCard;
                })
            ) as Observable<RateCard>;
    }

    updateCategoryRateCard(
        rateCardId: string,
        accessToken: string,
        payload: Object
    ): Observable<RateCard> {
        const headers = new HttpHeaders({
            Authorization: `Bearer ${accessToken}`,
        });

        return this.http
            .patch(`/beta/rateCards/${rateCardId}/update`, payload, { headers })
            .pipe(retryWhen(this.onError))
            .pipe(
                map((resp: any) => {
                    return resp.rateCard as RateCard;
                })
            ) as Observable<RateCard>;
    }

    deleteCategoryRateCard(
        rateCardId: string,
        accessToken: string
    ): Observable<any> {
        const headers = new HttpHeaders({
            Authorization: `Bearer ${accessToken}`,
        });

        return this.http
            .delete(`/beta/rateCards/${rateCardId}/update`, { headers })
            .pipe(retryWhen(this.onError));
    }
}
