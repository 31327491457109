import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { LoginComponent } from "./login/login.component";
import { PageNotFoundComponent } from "./page-not-found/page-not-found.component";
import { ConsoleComponent } from "./console/console.component";
import { OnboardingComponent } from "./onboarding/onboarding.component";
import { RideTrackingComponent } from "./ride-tracking/ride-tracking.component";
import { RideTrackingShortComponent } from "./ride-tracking-short/ride-tracking-short.component";
import { DriverDetailsComponent } from "./drivers/driver-details/driver-details.component";
import { CommuteDetailsComponent } from "./daily-commute/commute-details/commute-details.component";

const routes: Routes = [
    // {
    //     path: '',
    //     redirectTo: 'signin',
    //     pathMatch: 'full'
    // },
    {
        path: "",
        component: ConsoleComponent,
        children: [
            {
                path: "",
                loadChildren: () =>
                    import("./console/console-layout.module").then(
                        (m) => m.ConsoleLayoutModule
                    ),
            },
        ],
    },
    { path: "drivers/:id", component: DriverDetailsComponent },
    { path: "dailyCommute/:id", component: CommuteDetailsComponent },
    {
        path: "signin",
        component: LoginComponent,
    },
    {
        path: "dashboard",
        component: ConsoleComponent,
    },
    {
        path: "onboarding",
        component: OnboardingComponent,
    },
    {
        path: "track",
        component: RideTrackingComponent,
    },
    {
        path: "t", // trackRide changed to t because DLT constraints
        component: RideTrackingShortComponent,
    },
    {
        path: "**",
        component: PageNotFoundComponent,
    },
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule],
})
export class AppRoutingModule {}
