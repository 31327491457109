import { Component, OnInit, OnDestroy } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { Subscription } from "rxjs";
import { VehoWebService } from "src/app/services/veho-web.service";
import { AuthenticationService } from "src/app/services/authentication.service";
import { NotificationService } from "src/app/services/notification.service";

export interface DayCommute {
    week: string;
    pickupTime: string;
    dropTime: string;
}

export interface AccountDetails {
    id: string;
    companyName: string;
}

export interface DailyCommute {
    id: string;
    name: string;
    email: string;
    countryCode: string;
    phone: string;
    pickupAddress: string;
    dropAddress: string;
    pickupLatitude: string;
    pickupLongitude: string;
    dropLatitude: string;
    dropLongitude: string;
    status: number;
    enable: boolean;
    account?: AccountDetails;
    weeklyCommute: DayCommute[];
}

@Component({
    selector: "app-commute-details",
    templateUrl: "./commute-details.component.html",
    styleUrls: ["./commute-details.component.less"],
})
export class CommuteDetailsComponent implements OnInit, OnDestroy {
    subscription: Subscription;
    users: DailyCommute[] = [];

    constructor(
        private vehoWebService: VehoWebService,
        private authService: AuthenticationService,
        private notificationService: NotificationService,
        private route: ActivatedRoute
    ) {}

    ngOnInit() {
        this.subscription = this.route.params.subscribe((params) => {
            const id = params["id"];
            if (id) {
                this.loadDailyRideDetails(id);
            } else {
                console.error("No ride ID found in route parameters.");
            }
        });
    }

    loadDailyRideDetails(id: string) {
        if (!id) {
            console.error("Ride ID is missing, cannot fetch details.");
            return;
        }

        const accessToken = this.authService.currentToken.accessToken;

        this.vehoWebService.getDailyridedetails(accessToken, id).subscribe(
            (resp: any) => {
                // this.users = [resp];
                this.users = Array.isArray(resp) ? resp : [resp];
            },
            (err) => {
                this.notificationService.notifyError(
                    "Failed to load daily ride details."
                );
                console.error("Error loading daily ride details:", err);
            }
        );
    }

    goBack() {
        history.back();
    }

    ngOnDestroy() {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }
}
